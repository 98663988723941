<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      .card-body.py-2
        p 符合條件資料  共 123筆 / 1頁
        ul.list-unstyled 
          li 日期: YYYY/MM/DD - YYYY/MM/DD
          li 人員: 記者 1
          li 平均稿件PV X,XXX 
          li xx中心稿量佔比 25%

    .col-lg-12
      //- 表格
      .card 
        .card-body
          .header-row.mb-3.justify-content-end
            b-button.width-md(variant="primary" to="" )
              span 匯出報表  
          //- Table
          .table-responsive.mb-0
            b-table(
              responsive, 
              ref="dataTable",
              :items="getData", 
              :fields="fields", 
              :per-page="perPage", 
              :current-page="currentPage", 
            )
              //- 排名
              template(v-slot:cell(index)="row") {{ row.index + 1 }}

              //- 新聞標題
              template(v-slot:cell(title)="row") {{ row.item.title }}

              //- Page View(PV)
              template(v-slot:cell(pv)="row") {{ $root.common.formatNumber(row.item.pv, 0) }}

              //- PV佔比
              template(v-slot:cell(percentage)="row") {{ row.item.percentage }}%

              //- 愛心數
              template(v-slot:cell(like)="row") {{ row.item.like }}

              //- 收藏數
              template(v-slot:cell(favorite)="row") {{ row.item.favorite }}
              
              //- 分類
              template(v-slot:cell(type)="row") {{ row.item.type }}
              
              //- 稿件來源
              template(v-slot:cell(source)="row") {{ row.item.source }}

              //- 稿號
              template(v-slot:cell(code)="row") {{ row.item.code }}

              //- 出稿日期
              template(v-slot:cell(date)="row") {{ row.item.date }}
          .row.mb-md-2
            .col-12
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
    
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

const mockData = [
  { 
    title: '「綠營遛鳥俠」性騷手法曝光！徐巧芯：最少50至60人受害',
    editer: '記者1',
    unit: '即時中心',
    pv: 12000,
    percentage: 12,
    like: 30,
    favorite: 10,
    type: '政治',
    source: '自製稿',
    code: '34566',
    date: '2022/9/9 18:25',
    editer: '王大明',
    unit: '即時中心',
    count: 12000,
    'count-percentage': 38,
  }
]

/**
 * Starter component
 */
export default {
  name: 'NewsAnalysis',
  data () {
    return {
      title: "即時中心 - 人員上稿詳細資料 / 各中心數據查詢",
      items: [{
        text: "數據分析",
        href: "/dashboard",
      },
      {
        text: "各中心數據查詢",
        href: "/center-analysis",
      },
      {
        text: "即時中心 - 人員上稿詳細資料",
        active: true,
      }
      ],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortDesc: false,
      status: false,
      fields: [
        { key: 'index', label: "排名", class: 'width-auto center' },
        { key: 'title', label: "標題", class: 'width-auto center' },
        { key: "pv", label: "Page View(PV)", class: 'width-auto center' },
        { key: "percentage", label: "PV 佔比", class: 'width-auto center' },
        { key: "like", label: "愛心數", class: 'width-auto center' },
        { key: "favorite", label: "收藏數", class: 'width-auto center' },
        { key: "type", label: "分類", class: 'width-auto center' },
        { key: "source", label: "稿件來源", class: 'width-auto center' },
        { key: "code", label: "稿號", class: 'width-auto center' },
        { key: "date", label: "出稿日期", class: 'width-auto center' }
      ],
      searchData: {
        unit: [],
        type: [],
        source: [], 
        editer: ''
      },
      editer: [],
      currentEditer: false,
    };
  },
  methods: {
    reloadData () {
      this.$refs.dataTable.refresh()
    },
    // 獲得推播項目清單
    getData (_table, _callback) {
      let vm = this
      _callback(mockData)
      // this.$root.apis.getData({
      //   status_type: this.statusTab,
      //   page: this.currentPage
      // }, function (_response) {
      //   let response = _response.body.data
      //   vm.currentPage = response.page
      //   vm.totalRows = response.total
      //   vm.perPage = response.per_page
      //   _callback(response.items)
      // })
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  }
};
</script>
  
<style lang="sass" scoped>
.card-header
  background: #ffffff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
  
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  &.delete
    color: #333333
</style>
